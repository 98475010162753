import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import Axios from "axios";
import cross from "../../images/icon-cross.svg";
import {t} from "i18next";

const ContactForm = () => {
  return (
    <section className="contact-form" id="second">
      <div className="contact-form__wrapper wrapper">
        <h2 className="big-text big-text--black contact-form__title">
          {t('lead.contact-form.main-title')}
        </h2>
        <Formik
          initialValues={{
            name: "",
            email: "",
            phone: "",
            city: "Москва",
            city_add: "",
            message: "",
          }}
          validate={(values) => {
            const errors = {};
            if (
              !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
            ) {
              errors.email = "Неверный email";
            }

            if (!values.email) {
              errors.email = "Введите email";
            }

            if (!values.name) {
              errors.name = "Введите имя";
            }

            if (!/^[0-9]*$/.test(values.phone)) {
              errors.phone = "Только цифры";
            }

            if (!values.city && !values.city_add) {
              errors.city = "Выберите город"
            }

            if (!values.message) {
              errors.message = "Добавьте комментарий";
            }

            return errors;
          }}
          onSubmit={(values, { setSubmitting, resetForm, initialValues }) => {
            resetForm({ values: initialValues });
            setTimeout(() => {
              const axiosConfig = {
                headers: {
                  "Content-type": "application/json",
                },
              };
              Axios.post(
                "https://ntf.aquaart.tech/aquaart/forms",
                values,
                axiosConfig
              )
                .catch((e) => {
                  console.log(e);
                })
                .finally(() => {
                  console.log("Данные прошли!");
                  setSubmitting(false);
                  document
                    .querySelector(".modal-wrapper")
                    .classList.add("modal-wrapper--active");
                  document
                    .querySelector(".modal__close")
                    .addEventListener("click", function () {
                      document
                        .querySelector(".modal-wrapper")
                        .classList.remove("modal-wrapper--active");
                    });
                });
            }, 400);
          }}
        >
          {({ isSubmitting }) => (
            <Form>
              <div className="form">
                <div className="form__full-row">
                  <label htmlFor="name">
                    <p className="form__label-text form__label-text--required">
                      {t('lead.contact-form.your-name')}
                    </p>
                    <Field type="text" name="name" />
                    <ErrorMessage
                      name="name"
                      component="div"
                      className="error-div"
                    />
                  </label>
                </div>
                <div className="form__half-row">
                  <label htmlFor="email">
                    <p className="form__label-text form__label-text--required">
                      E-mail:
                    </p>
                    <Field type="email" name="email" />
                    <ErrorMessage
                      name="email"
                      component="div"
                      className="error-div"
                    />
                  </label>
                </div>
                <div className="form__half-row">
                  <label htmlFor="phone">
                    <p className="form__label-text">{t('lead.contact-form.your-phone')}</p>
                    <Field type="text" name="phone" className="js-phone" />
                    <ErrorMessage
                      name="phone"
                      component="div"
                      className="error-div"
                    />
                  </label>
                </div>
                <div className="form__half-row">
                  <label htmlFor="city">
                    <p className="form__label-text">{t('lead.contact-form.your-city')}</p>
                    <Field
                      as="select"
                      name="city"
                      placeholder="Выберите из списка"
                      className="select"
                    >
                      <option value="Выберите город" disabled defaultValue>
                        {t('lead.contact-form.select-city')}
                      </option>

                      <option value="Cанкт-Петербург">Cанкт-Петербург</option>
                      <option value="Москва">Москва</option>
                    </Field>
                    <ErrorMessage
                      name="city"
                      component="div"
                      className="error-div"
                    />
                  </label>
                </div>
                <div className="form__half-row">
                  <label htmlFor="phone">
                    <p className="form__label-text">
                      {t('lead.contact-form.fill-city')}
                    </p>
                    <Field type="text" name="city_add" />
                  </label>
                </div>
                <div className="form__full-row">
                  <label htmlFor="message">
                    <p className="form__label-text form__label-text--required">
                      {t('lead.contact-form.message')}
                    </p>
                    <Field type="text" as="textarea" name="message" />
                    <ErrorMessage
                      name="message"
                      component="div"
                      className="error-div"
                    />
                  </label>
                </div>
                <div className="form__half-row">
                  <button
                    type="submit"
                    disabled={isSubmitting}
                    className="form__submit"
                  >
                    {t('lead.contact-form.submit')}
                  </button>
                  <div className="form__tooltip">
                    <p>
                      {t('lead.contact-form.tip')}
                    </p>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>

      <div className="modal-wrapper">
        <div className="modal">
          <button className="modal__close">
            <img src={cross} alt="Закрыть форму" title="Закрыть форму" />
          </button>
          <p>{t('lead.contact-form.thanks')}</p>
        </div>
      </div>
    </section>
  );
};

export default ContactForm;
